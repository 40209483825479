// import { useMemo } from 'react';
// import { useEffect } from 'react';
// import { useEffect } from 'react';
// import { useLocalStorage } from 'react-use';
// import usePagination from '@mui/material/usePagination';
import List from '@mui/material/List';

import {
  SuppressionItem,
  SuppressionData,
} from './SuppressionItem';

export const SuppressionList = ({ data }: {data: SuppressionData[]}): JSX.Element => {
  // const [storedState] = useLocalStorage('storedSuppression', [0]);

  // useEffect(() => {
  //   console.log('aaaa');
  // }, [checked]);

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {data.map((item) => {
        return (
          <div key={item.id}>
            {/* <SuppressionItem item={item} storedState={storedState} /> */}
            <SuppressionItem item={item} />
          </div>
        );
      })}
    </List>
  );
};
