import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';

// import logo from './logo.svg';
// import './App.css';
// import routes from './routes';
import HomeIndex from './features/home/pages/Index';
import UnderConstructionIndex from './features/underconstruction/pages/Index';
import { SuppressionIndex } from './features/suppression/pages/Index';

const App: React.VFC = () => {
  // return <BrowserRouter>{routes}</BrowserRouter>;
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeIndex />} />
        <Route path="/search" element={<UnderConstructionIndex />} />
        <Route path="/suppression" element={<SuppressionIndex />} />
      </Routes>
    </BrowserRouter>
  );
};

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default App;
