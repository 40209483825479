import React, {
  useState,
  useEffect,
} from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
// CSS Normalize
import CssBaseline from '@mui/material/CssBaseline';
import {
  // styled,
  // useTheme,
  // Theme,
  createTheme,
  ThemeProvider,
  // CSSObject,
} from '@mui/material/styles';
// import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

import { DrawerTemplate } from './DrawerTemplate';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2a2a2b',
    },
    secondary: {
      main: '#f50057',
    },
    mode: 'light',
    // mode: 'dark',
  },
  // components: {
  //   MuiLink: {
  //     defaultProps: {
  //       underline: 'none',
  //     },
  //   },
  // },
});

export const BaseTemplate = ({ children, title }: { children: React.ReactNode, title: string }): JSX.Element => {
  // メニュー展開
  const [open, setOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    console.log(matches);
  }, [matches]);

  // メニュー展開ハンドラ
  const toggleDrawer = () => {
    if (matches === true) {
      setOpen(!open);
    } else {
      setMobileOpen(!mobileOpen);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ zIndex: (themes) => themes.zIndex.drawer + 1 }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <DrawerTemplate open={open} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
};
