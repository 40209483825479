import {
  createContext,
  useEffect,
  useState,
  useCallback,
  // useMemo,
} from 'react';
// import { useLocalStorage } from 'react-use';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {
  // Badge,
  Box,
  // Chip,
  Divider,
  Fade,
  IconButton,
  // List,
  // ListItem,
  // Paper,
  Tooltip,
} from '@mui/material';
// import { Label } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';

import { CompletedFilterSwitch } from '../components/CompletedFilterSwitch';

import { BaseTemplate } from '../../../components/templates/BaseTemplate';
import { GenericDialog } from '@/components/parts/GenericDialog';

import { SuppressionList } from '../components/SuppressionList';
import { SuppressionData } from '../components/SuppressionItem';

import Data from '../assets/suppression2022full.json';

export const CompletedSwitchState = createContext(Boolean);

export const SuppressionIndex = (): JSX.Element => {
  const [checked, setChecked] = useState(false);
  const data = Data.data as SuppressionData[];
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [done, setDone] = useState(0);

  const [filteredData, setFilteredData] = useState(data.slice(0, 10));

  const onUpdateHandler = () => {
    updatePage(currentPage, checked);
  };

  useEffect(() => {
    updatePage(currentPage, checked);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    if (checked) {
      setLastPage(Math.ceil((data.length - done - 1) / 10));
    } else {
      setLastPage(Math.ceil((data.length) / 10));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData]);

  useEffect(() => {
    setLastPage(Math.ceil(data.length / 10));
    updatePage(1, checked);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageChangedHandler = (_event: React.ChangeEvent<unknown>, value: number) => {
    updatePage(value, checked);
  };

  const updatePage = useCallback((page: number, isChecked: boolean) => {
    setCurrentPage(page);

    const filterd = data.filter((output) => {
      let result = true;
      if (isChecked) {
        const buf: string = window.localStorage.getItem('storedSuppression') ?? '';
        const stored = JSON.parse(buf) as number[];
        result = stored.indexOf(output.id) === -1;
      }

      return result;
    });
    setDone(getStoredIds().length - 1);
    setFilteredData(filterd.slice((page - 1) * 10, ((page - 1) * 10) + 10));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStoredIds = useCallback(() => {
    const buf: string = window.localStorage.getItem('storedSuppression') ?? '';
    const stored = JSON.parse(buf) as number[];
    return stored;
  }, []);

  const resetDataHandler = () => {
    window.localStorage.setItem('storedSuppression', JSON.stringify([0]));
    window.location.reload();
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <BaseTemplate title="大討伐2022">
      <Stack pb={1} spacing={2} direction="row" sx={{ flexGrow: 1 }}>
        <Button>
          {`${done}/${data.length}`}
        </Button>
        <CompletedFilterSwitch setChecked={setChecked} />
        <Box sx={{ flexGrow: 1 }} />
        <Tooltip
          title="表示を更新します。"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          arrow
        >
          <IconButton aria-label="refresh" color="primary" onClick={onUpdateHandler}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="討伐データを初期化します。"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          arrow
        >
          <IconButton aria-label="delete" color="primary" onClick={handleDialogOpen}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <GenericDialog
          title="討伐データ初期化"
          openDialog={openDialog}
          onCancel={handleDialogClose}
          onAgree={resetDataHandler}
        >
          現状の討伐データを削除して初期状態に戻します。
        </GenericDialog>
      </Stack>
      {/* <Divider /> */}
      {/* <Box>
        <Paper
          elevation={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
            p: 0.5,
            m: 0,
          }}
          component="ul"
        >
          <List
            sx={{
              display: 'flex',
              flexDirection: 'row',
              padding: 0,
            }}
          >
            <ListItem>
              <Badge badgeContent={4} color="primary">
                <Chip label="生体研究所2F" />
              </Badge>
            </ListItem>
            <ListItem>
              <Badge badgeContent={4} color="primary">
                <Chip label="生体研究所2F" />
              </Badge>
            </ListItem>
          </List>
        </Paper>
      </Box> */}
      {/* <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}> */}
      <SuppressionList data={filteredData} />
      <Divider />
      <Stack pt={1} spacing={2} alignItems="center">
        <Pagination
          count={lastPage}
          page={currentPage}
          color="primary"
          onChange={pageChangedHandler}
          sx={{ display: { xs: 'none', sm: 'block' } }}
        />
        <Pagination
          count={lastPage}
          page={currentPage}
          color="primary"
          onChange={pageChangedHandler}
          size="small"
          sx={{ display: { xs: 'block', sm: 'none' } }}
        />
      </Stack>
    </BaseTemplate>
  );
};
