import {
  FC,
  ReactNode,
} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

type GenericDialogProps = {
  children: ReactNode;
  openDialog: boolean;
  title: string;
  cancelLabel?: string;
  onCancel: () => void;
  onAgree: () => void;
}

export const GenericDialog:FC<GenericDialogProps> = ({
  children,
  openDialog,
  title,
  cancelLabel = 'キャンセル',
  onCancel,
  onAgree,
}):JSX.Element => {
  return (
    <Dialog
      open={openDialog}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{cancelLabel}</Button>
        <Button onClick={onAgree} autoFocus>
          削除
        </Button>
      </DialogActions>
    </Dialog>
  );
};
