import {
  useMemo,
  useCallback,
  useEffect,
  Fragment,
} from 'react';
import { useToggle } from 'react-use';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
// import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  // Box,
  Stack,
  Chip,
  Typography,
  Tooltip,
  // Link,
  // Button,
} from '@mui/material';

export type SuppressionData = {
  id: number;
  category: string;
  name: string;
  habitat: string;
  area: string;
  route: string;
  transport: string;
  priority: string;
}

export const SuppressionItem = ({
  item,
  // storedState,
  // setStoredState,
}: {
   item: SuppressionData,
  //  storedState: number[] | undefined,
  }): JSX.Element => {
  const labelId = `checkbox-list-label-${item.id}`;
  const initJsonString = window.localStorage.getItem('storedSuppression');
  const initIds = initJsonString === null ? [0] : JSON.parse(initJsonString) as number[];
  const [checked, toggle] = useToggle(initIds.indexOf(item.id) !== -1);

  const handleToggle = useCallback((value: number) => () => {
    if (checked === undefined || initIds === undefined) {
      return;
    }

    const buf: string = window.localStorage.getItem('storedSuppression') ?? '[0]';
    const stored = JSON.parse(buf) as number[];

    toggle();

    const newStored = [...stored];
    const currentIndex = newStored.indexOf(value);

    // 値が見つからなかった場合 (新規追加)
    if (currentIndex === -1) {
      newStored.push(value);
    } else {
      // 値が見つかった場合（削除）
      newStored.splice(currentIndex, 1);
    }

    window.localStorage.setItem('storedSuppression', JSON.stringify(newStored));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    window.localStorage.setItem('storedSuppression', JSON.stringify(initIds));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => {
    return (
      <ListItem
        key={item.id}
        secondaryAction={(
          <CopyToClipboard
            text={`${item.name}`}
          >
            <IconButton edge="end" aria-label="copy">
              <ContentCopyIcon />
            </IconButton>
          </CopyToClipboard>
            )}
        disablePadding
      >
        <ListItemButton role={undefined} onClick={handleToggle(item.id)} dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          <ListItemText
            id={labelId}
            // primary={`${item.name}`}
            primary={(
              // eslint-disable-next-line react/jsx-fragments
              <Stack spacing={1} direction="row" sx={{ flexGrow: 1 }}>
                <Typography
                  sx={{ display: 'inline', fontSize: '1.2em' }}
                  component="span"
                  variant="body2"
                  color="secondary"
                >
                  {`${item.name}`}
                </Typography>
                <Tooltip title={item.transport}>
                  <Chip
                    label={item.route}
                    size="small"
                    sx={{
                      fontSize: '0.5em',
                      display: { xs: 'none', sm: 'inherit' },
                    }}
                  />
                </Tooltip>
                <Chip
                  label={item.category}
                  size="small"
                  sx={{
                    fontSize: '0.5em',
                    display: { xs: 'none', sm: 'inherit' },
                  }}
                />
              </Stack>
            )}
            secondary={(
              // <Stack pt={0.5} direction="row" alignItems="center" sx={{ flexGrow: 1 }}>
              // eslint-disable-next-line react/jsx-fragments
              <Fragment>
                <Typography
                  sx={{
                    display: 'inline',
                    fontSize: { xs: '0.6em', sm: '0.8em' },
                  }}
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  {`${item.habitat}`}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.6em',
                    display: { xs: 'inline', sm: 'none' },
                  }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {` (${item.route})`}
                </Typography>
              </Fragment>
            )}
          />
        </ListItemButton>
      </ListItem>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);
};
