import React, { Dispatch, SetStateAction } from 'react';

import Switch from '@mui/material/Switch';
import { Fade, Tooltip } from '@mui/material';
// import { FormControlLabel, FormGroup } from '@mui/material';

export const CompletedFilterSwitch = ({ setChecked }: {setChecked: Dispatch<SetStateAction<boolean>>}): JSX.Element => {
  const [switched, setSwitched] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitched(event.target.checked);
    setChecked(event.target.checked);
  };

  return (
    <Tooltip
      title="討伐済みのモンスターを非表示にします。"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      arrow
    >
      <Switch
        checked={switched}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </Tooltip>
  );
};
