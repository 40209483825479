// import {
//   useEffect,
//   useState
// } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// アイコンボタン
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import {
  styled,
  // useTheme,
  Theme,
  CSSObject,
  // useTheme,
} from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/styles';
import { Link } from 'react-router-dom';
// import { useEffect } from 'react';

// xs: 100, // theme.breakpoints.up('xs')
// sm: 200, // theme.breakpoints.up('sm')
// md: 300, // theme.breakpoints.up('md')
// lg: 400, // theme.breakpoints.up('lg')
// xl: 500, // theme.breakpoints.up('xl')

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  width: drawerWidth,
  flexShrink: 0,
  [theme.breakpoints.down('md')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  // mainの回り込み回避
  flexShrink: 0,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const StyledDrawoer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const StyledLink = styled(Link)({
  textDecoration: 'none',
  // ここに書いても効かない…
  // color: 'text.secondary',
});

const Navigation = ({ setMobileOpen = undefined }: {setMobileOpen: React.Dispatch<React.SetStateAction<boolean>> | undefined}) => {
  const toggleState = () => {
    if (setMobileOpen !== undefined) {
      setMobileOpen(false);
    }
  };

  return (
    <Box onClick={toggleState}>
      <List>
        <StyledLink to="/" sx={{ color: 'text.secondary' }}>
          <ListItem button>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="トップページ" />
          </ListItem>
        </StyledLink>
        <StyledLink to="/search" sx={{ color: 'text.secondary' }}>
          <ListItem button>
            <ListItemIcon>
              <ShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary="アイテム検索" />
          </ListItem>
        </StyledLink>
        <StyledLink to="/suppression" sx={{ color: 'text.secondary' }}>
          <ListItem button>
            <ListItemIcon>
              <PlaylistAddCheckIcon />
            </ListItemIcon>
            <ListItemText primary="大討伐2022" />
          </ListItem>
        </StyledLink>
      </List>
      <Divider />
    </Box>
  );
};

export const DrawerTemplate = ({
  open,
  setMobileOpen,
  mobileOpen,
}: {
  open: boolean,
  setMobileOpen: React.Dispatch<React.SetStateAction<boolean>>,
  mobileOpen: boolean,
}): JSX.Element => {
  return (
    <Box>
      <StyledDrawoer
        variant="permanent"
        open={open}
        sx={{ display: { xs: 'none', sm: 'block' } }}
      >
        <Toolbar />
        <Navigation setMobileOpen={undefined} />
      </StyledDrawoer>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={() => setMobileOpen(false)}
        ModalProps={{
          keepMounted: false, // Better open performance on mobile.
        }}
        sx={{
          display: { sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        <Toolbar />
        <Navigation setMobileOpen={setMobileOpen} />
      </Drawer>
    </Box>
  );
};
