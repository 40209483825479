import { BaseTemplate } from '../../../components/templates/BaseTemplate';

const UnderConstructionIndex = (): JSX.Element => {
  return (
    <BaseTemplate title="UnderConstruction">
      こうじちゅう。
    </BaseTemplate>
  );
};

export default UnderConstructionIndex;
